import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import HeaderLine from "../components/HeaderLine"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Footer from "../components/Footer"

const projects = ({ pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // console.log("-------", location)

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  // const customCrumbLabel = location.pathname.toLowerCase().replace("-", " ")
  return (
    <Layout>
      <HeaderLine />
      <div className="container mx-auto max-w-screen-xl py-8 pb-2 container-pad">
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          // crumbLabel={customCrumbLabel}
          crumbLabel="Проекты"
        />
        {/* <Breadcrumb location={location} crumbLabel="НАШИ ПРОЕКТЫ" /> */}
      </div>

      <div className="container mx-auto max-w-screen-xl flex-none text-center text-3xl sm:text-5xl leading-none py-4 pb-8 container-pad">
        НАШИ ПРОЕКТЫ
      </div>

      <Projects />
      <Footer />
    </Layout>
  )
}

export default projects
