import React from "react"
// import PropTypes from "prop-types"
// import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { AiOutlinePlusCircle } from "react-icons/Ai"
// import { BiInfoCircle } from "react-icons/bi"
import { graphql, useStaticQuery } from "gatsby"
// import Title from "./Title"
// import project1 from "../assets/projects/project-1.jpg"
// import project2 from "../assets/projects/project-3.jpg"
// import project3 from "../assets/projects/project-2.jpg"
// import project4 from "../assets/projects/project-2.jpg"
// import project5 from "../assets/projects/project-4.jpg"
// import project6 from "../assets/projects/project-5.jpg"

// const images = [project1, project2, project3, project4, project5, project6]

const query = graphql`
  {
    allStrapiProjects(
      sort: { fields: date_finish, order: DESC }
      filter: { publish: { eq: true } }
    ) {
      nodes {
        id
        strapiId
        name
        slug
        description_short
        date_finish(formatString: "YYYY, MMMM", locale: "ru")
        date_continue
        image_small {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const Project = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiProjects: { nodes: projects },
  } = data
  // const [value, setValue] = React.useState(0)
  // const { name, description_short, image_small } = projects[value]

  // console.log(name, description_short, image_small)
  // console.log(image_small)

  return (
    <div>
      <div className="container container-pad mx-auto max-w-screen-xl mb-8">
        <div className="pb-2 text-base leading-5 text-justify">
          На отечественном рынке компания работает <b>с&nbsp;2001&nbsp;года</b>.
          Начав свой путь с программных решений в сфере информационных
          технологий пройден путь от решения локальных задач по автоматизации
          отдельных бизнес- и технологических процессов до разработки и
          внедрения полномасштабных проектов по оснащению зданий и сооружений
          инженерными системами.
        </div>
        {/* <div className="container container-pad mx-auto max-w-screen-xl mb-8 text-sm">
          <BiInfoCircle className="text-base inline mr-2 mb-1" />
          Раздел находится в процессе наполнения. За 20 лет работы выполнено
          много разных проектов. На данный момент ведется работа по подготовке
          информации о проектах для публикации. По части проектам информацию
          можно найти&nbsp;
          <a
            className="text-blue-500"
            href="https://www.rishon.ru/ru/projects/realizovannye-proekty"
            target="_blank"
          >
            на старой версии сайта
          </a>
        </div> */}

        <div className="flex items-center pt-2">
          {/* <BiInfoCircle className="flex-none text-2xl mr-3" /> */}
          <div
            className="leading-5 text-base text-justify"
            style={{ color: "#d2691e" }}
          >
            Раздел находится в процессе наполнения. За 20 лет работы выполнено
            много разных проектов. На данный момент ведется работа по подготовке
            информации о проектах для публикации. По части проектам информацию
            можно найти&nbsp;{" "}
            <a
              className="text-blue-500"
              href="https://old.rishon.ru/ru/projects/realizovannye-proekty"
              target="_blank"
              rel="noreferrer"
            >
              на старой версии сайта
            </a>
          </div>
        </div>
      </div>
      <div className="container container-pad mx-auto max-w-screen-xl mb-8">
        <div className="flex flex-wrap -ml-3 -mr-3">
          {projects.map((item, index) => {
            // console.log("item", item.image_small.childImageSharp.fluid)
            return (
              <div
                className="project__card pl-3 pr-3"
                key={item.strapiId}
                // onClick={() => setValue(index)}
              >
                <div className="project__post mb-3">
                  {/* <img className="project__img" src={images[index]} /> */}
                  {/* <div
                    className="project__img"
                    style={{ backgroundImage: `url(${images[index]})` }}
                  ></div> */}
                  {/* <div
                    className="project__img"
                    style={{
                      backgroundImage: `url(${item.image_small.childImageSharp.fixed})`,
                    }}
                  ></div> */}

                  <BackgroundImage
                    Tag="section"
                    className="project__img"
                    fluid={item.image_small.childImageSharp.fluid}
                    backgroundColor={`#1e3a68`}
                  >
                    {/* <h2>gatsby-background-image</h2> */}
                  </BackgroundImage>

                  {/* <Image
                    fixed={item.image_small.childImageSharp.fixed}
                    className="flex-auto proj-img"
                  /> */}

                  <Link to={`/projects/${item.slug}`} className="project__over">
                    <div className="project__icon">
                      <AiOutlinePlusCircle />
                    </div>
                  </Link>
                </div>
                <div className="project__text pb-6">
                  <div className="text-2xl font-bold tracking-wider sm:text-base md:text-lg lg:text-xl xl:text-2xl pb-4 lg:leading-8">
                    <Link to={`/projects/${item.slug}`}>{item.name}</Link>
                  </div>

                  {item.date_continue ? (
                    <div>в работе</div>
                  ) : (
                    <div style={{ textTransform: "capitalize" }}>
                      {item.date_finish}
                    </div>
                  )}

                  <div className="text-base lg:block md:text-sm lg:text-sm leading-5">
                    {item.description_short}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Project.propTypes = {}

export default Project
